<!--
 * @Description:维护管理 maintentManage
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-20 14:09:58
 * @LastEditors: zhoucheng
-->
<template>
  <div class='maintentManage-mainBody'>
    <van-nav-bar title="维护管理"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickBack" />
    <van-row class="lineRow">
      <!-- 待确认 -->
      <van-row class="lineRow-item"
               @click="handleClickWaitConfirm">
        <van-col class="lineRow-item-img">
          <img src="@/assets/maintentManage/waitConfirm.png"
               alt="">
        </van-col>
        <van-col class="lineRow-item-text">待确认工单</van-col>
        <van-col class="lineRow-item-number">{{waitConfirmNumber}}</van-col>
        <van-col class="lineRow-item-right">
          <van-icon name="arrow" />
        </van-col>
      </van-row>
      <van-row class="rowLine"></van-row>
      <!-- 待处理 -->
      <van-row class="lineRow-item"
               @click="handleClickWaitDispose">
        <van-col class="lineRow-item-img">
          <img src="@/assets/maintentManage/waitDispose.png"
               alt="">
        </van-col>
        <van-col class="lineRow-item-text">待处理工单</van-col>
        <van-col class="lineRow-item-number">{{waitDisPoseNumber}}</van-col>
        <van-col class="lineRow-item-right">
          <van-icon name="arrow" />
        </van-col>
      </van-row>
    </van-row>
    <van-row class="lineRow">
      <!-- 待复核 -->
      <van-row class="lineRow-item"
               @click="handleClickWaitPrecheck">
        <van-col class="lineRow-item-img">
          <img src="@/assets/maintentManage/waitPrecheck.png"
               alt="">
        </van-col>
        <van-col class="lineRow-item-text">待复核工单</van-col>
        <van-col class="lineRow-item-number">{{waitPrecheckNumber}}</van-col>
        <van-col class="lineRow-item-right">
          <van-icon name="arrow" />
        </van-col>
      </van-row>
      <van-row class="rowLine"></van-row>
      <!-- 已完成 -->
      <van-row class="lineRow-item"
               @click="handleClickCompletedWork">
        <van-col class="lineRow-item-img">
          <img src="@/assets/maintentManage/completedWork.png"
               alt="">
        </van-col>
        <van-col class="lineRow-item-text">已完成工单</van-col>
        <van-col class="lineRow-item-number">{{completedNumber}}</van-col>
        <van-col class="lineRow-item-right">
          <van-icon name="arrow" />
        </van-col>
      </van-row>
    </van-row>
    <van-row class="uploadWorkClass">
      <van-col class="uploadWorkClass-left">
        <van-row class="uploadWorkClass-left-lineone">设备故障 出现状况</van-row>
        <van-row class="uploadWorkClass-left-linetwo">填写信息 一键上报</van-row>
      </van-col>
      <van-col class="uploadWorkClass-right">
        <van-button class="uploadWorkClass-right-button"
                    @click="handleClickUploadWorkList">上报工单</van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      waitConfirmNumber: '0', // 待处理
      waitDisPoseNumber: '0', // 待确认
      waitPrecheckNumber: '0', // 待复核
      completedNumber: '0' // 已完成
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryWorkListNumber()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 查询工单个数
    queryWorkListNumber () {
      this.$maintentManage.queryWorkTicketCount({}).then(res => {
        res.resultEntity.forEach(item => {
          if (item.workTicketStatus === 1) {
            this.waitConfirmNumber = item.count
          } else if (item.workTicketStatus === 2) {
            this.waitDisPoseNumber = item.count
          } else if (item.workTicketStatus === 3) {
            this.waitPrecheckNumber = item.count
          } else if (item.workTicketStatus === 4) {
            this.completedNumber = item.count
          }
        })
      })
    },
    // 点击待确认
    handleClickWaitConfirm () {
      this.$router.push('/waitConfirmWork')
    },
    // 点击待处理
    handleClickWaitDispose () {
      this.$router.push('/waitDisposeWork')
    },
    // 点击待复核
    handleClickWaitPrecheck () {
      this.$router.push('/waitPrecheckWork')
    },
    // 点击已完成
    handleClickCompletedWork () {
      this.$router.push('/completedWork')
    },
    // 点击上报工单
    handleClickUploadWorkList () {
      this.$router.push('/uploadWorkList')
    },
    // 顶部返回按钮
    handleClickBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.maintentManage-mainBody {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .lineRow {
    width: 93.6%;
    height: 15%;
    background: #ffffff;
    margin-left: 3.2%;
    margin-top: 2%;
    .rowLine {
      width: 100%;
      height: 1px;
      background: #e7e8e8;
    }
    .lineRow-item {
      width: 100%;
      height: 50%;
      display: flex;
      align-items: center;
      position: relative;
      .lineRow-item-img {
        padding-left: 3.7%;
      }
      .lineRow-item-text {
        margin-left: 3.7%;
        font-family: PingFang-SC-Medium;
        font-size: 17px;
        color: #333333;
      }
      .lineRow-item-number {
        position: absolute;
        float: right;
        right: 10%;
        font-family: PingFang-SC-Medium;
        font-size: 17px;
        color: #999999;
      }
      .lineRow-item-right {
        height: 100%;
        display: flex;
        align-items: center;
        position: absolute;
        float: right;
        right: 3.4%;
        font-size: 18px;
        color: #999999;
      }
    }
  }
  .uploadWorkClass {
    width: 93.6%;
    height: 12%;
    margin-left: 3.2%;
    margin-top: 2%;
    background-image: url('../../assets/maintentManage/uoloadBack.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .uploadWorkClass-left {
      width: 65%;
      height: 100%;
      font-size: 17px;
      font-family: SourceHanSansCN-Medium;
      font-weight: 600;
      // background: linear-gradient(#92cef1, #27a6f0);
      background: rgb(16, 233, 222);
      -webkit-background-clip: text;
      color: transparent;
      .uploadWorkClass-left-lineone {
        margin-left: 5%;
        margin-top: 7%;
      }
      .uploadWorkClass-left-linetwo {
        margin-left: 16%;
        margin-top: 2%;
      }
    }
    .uploadWorkClass-right {
      width: 35%;
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 2%;
      .uploadWorkClass-right-button {
        width: 90px;
        height: 34px;
        background: linear-gradient(to bottom, #70cbff, #19a9fc, #4bbbfb);
        border: none;
        border-radius: 20px;
        color: #ffffff;
        font-family: PingFang-SC-Medium;
        font-size: 15px;
      }
    }
  }
}
</style>
